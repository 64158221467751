<script>
import { VclCode } from 'vue-content-loading';
import { mapState } from "vuex";

export default {
  components: {
    VclCode
  },
  props: {

  },
  computed: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/dashboard/transactions', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.data = data;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  created() {
    this.getData()
    this.timer = setInterval(() => {this.getData();}, 1000 * 60);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      data: {},
      timer: null
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">

          <div class="col-lg-4 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fad fa-funnel-dollar text-white"></i>
                    </span>
                  </div>
                  <h4 class="ml-3 mb-0">Transactions</h4>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{ $n(data.periods.current.count, {locale: 'de'}) }}
                    <template v-if="data.trends.count.direction == 1">
                      <i class="mdi mdi-chevron-up ms-1 text-success"></i>
                    </template>
                    <template v-else-if="data.trends.count.direction == -1">
                      <i class="mdi mdi-chevron-down ms-1 text-danger"></i>
                    </template>
                  </h4>
                  <div class="d-flex">
                    <template v-if="data.trends.count.direction == 1">
                      <span class="badge badge-soft-success font-size-12">+{{$n(data.trends.count.value, {locale: 'de'})}} %</span>
                    </template>
                    <template v-else-if="data.trends.count.direction == -1">
                      <span class="badge badge-soft-danger font-size-12">{{$n(data.trends.count.value, {locale: 'de'})}} %</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-soft-warning font-size-12">0 %</span>
                    </template>

                    <span class="ms-2 ml-1 text-truncate">from last month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fas fa-euro-sign text-white"></i>
                    </span>
                  </div>
                  <h4 class="ml-3 mb-0">Total value</h4>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{ $n(data.periods.current.value, {locale: 'de'}) }} €
                    <template v-if="data.trends.value.direction == 1">
                      <i class="mdi mdi-chevron-up ms-1 text-success"></i>
                    </template>
                    <template v-else-if="data.trends.value.direction == -1">
                      <i class="mdi mdi-chevron-down ms-1 text-danger"></i>
                    </template>
                  </h4>
                  <div class="d-flex">
                    <template v-if="data.trends.value.direction == 1">
                      <span class="badge badge-soft-success font-size-12">+{{$n(data.trends.value.value, {locale: 'de'})}} %</span>
                    </template>
                    <template v-else-if="data.trends.value.direction == -1">
                      <span class="badge badge-soft-danger font-size-12">{{$n(data.trends.value.value, {locale: 'de'})}} %</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-soft-warning font-size-12">0 %</span>
                    </template>

                    <span class="ms-2 ml-1 text-truncate">from last month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fas fa-badge-dollar text-white"></i>
                    </span>
                  </div>
                  <h4 class="ml-3 mb-0">Transaction average</h4>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{ $n(data.periods.current.average, {locale: 'de'}) }} €
                    <template v-if="data.trends.average.direction == 1">
                      <i class="mdi mdi-chevron-up ms-1 text-success"></i>
                    </template>
                    <template v-else-if="data.trends.average.direction == -1">
                      <i class="mdi mdi-chevron-down ms-1 text-danger"></i>
                    </template>
                  </h4>
                  <div class="d-flex">
                    <template v-if="data.trends.average.direction == 1">
                      <span class="badge badge-soft-success font-size-12">+{{$n(data.trends.average.value, {locale: 'de'})}} %</span>
                    </template>
                    <template v-else-if="data.trends.average.direction == -1">
                      <span class="badge badge-soft-danger font-size-12">{{$n(data.trends.average.value, {locale: 'de'})}} %</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-soft-warning font-size-12">0 %</span>
                    </template>

                    <span class="ms-2 ml-1 text-truncate">from last month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
